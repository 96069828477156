<template>
  <div style="overflow-x: scroll">
    <p
      v-if="hideBackButton"
      class="nnn pointer pl20 mt40"
      @click="$router.push('/events')"
    >
      <i class="angle left icon"></i> Back To Events
    </p>
    <iframe id="txtArea1" style="display: none"></iframe>
    <div class="flex wrap" style="flex-wrap: true">
      <div
        v-for="me in events"
        :key="me.id"
        :class="{ active: activeEventId == me.id }"
        @click="
          $bus.redirect(
            `https://ethosempowers.com/#/ideace/${me.id}/registrations`,
            false,
            true
          )
        "
        class="me_chip"
      >
        {{ me.Title }}
      </div>
    </div>
    <page-header
      v-if="event.Title"
      :header="event.Title + ' Registrations'"
      :tabs="registrationTabs"
      @tabClicked="changeState(arguments[0].value)"
    />
    <div v-if="event.Title" class="wfill flex">
      <button @click="exportCsv" class="mlauto black mt16">Download CSV</button>
      <button
        class="ml8 brown500 mt16"
        v-if="event.jury && event.jury.length > 0"
        @click="showJuryMailPopup"
      >
        Send Jury Email
      </button>
      <button @click="showMailPopup" class="ml8 brown400 mt16 mr20">
        Send Participant Mail
      </button>
      <mail-popup :emails="emails" ref="mailPopup" />
      <mail-popup :emails="event.jury" ref="juryMailPopup" />
    </div>

    <p></p>
    <pagination
      :pages="Math.ceil(regCounts['Total'] / 500)"
      @gotoPage="getDataForPage(...arguments)"
    />
    <table
      class="ui celled table"
      id="regTable"
      style="width: calc(100% - 40px); margin: 20px"
    >
      <thead>
        <tr>
          <th>Registration ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Contact</th>
          <th>Type</th>
          <th>Institute</th>
          <th>Place of Work</th>
          <th>Graduation Year</th>
          <th>Registered On</th>
          <th>Submissions</th>
          <th>Display</th>
          <th>Jury Member</th>
          <th>Other Data</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(ent, i) of registrations" :key="'registration-' + i">
          <td>{{ ent.id }}</td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              {{ u.name }}
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              {{ u.email }}
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              {{ u.contact }}
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              {{ u.type }}
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              <span v-if="u.institute == 'Not Listed'"
                >{{ u.institute }} - {{ u.notListedInstitute }}</span
              >
              <span v-else>{{ u.institute }}</span>
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              <span>{{ u.companyName }}, {{ u.location }}</span>
            </p>
          </td>
          <td>
            <p v-for="(u, ix) in ent.users" :key="i + '-username-' + ix">
              <span>{{ u.yearOfGraduation }}</span>
            </p>
          </td>
          <td>
            {{ ent.created_at }}
          </td>
          <td>
            <p
              style="font-size: 10px"
              v-for="f in ent.files"
              @click="$bus.redirect(f.url, false, true, ent.id)"
              :key="f.id"
              class="pointer"
            >
              {{ f.name }}_original
            </p>
            <p
              style="font-size: 10px"
              v-for="f in ent.files"
              @click="$bus.redirect(f.url, true, false, ent.id)"
              :key="f.id"
              class="pointer"
            >
              {{ f.name }}_shareable
            </p>
          </td>
          <td>
            <input
              @change="
                (evt) => {
                  displayEntry(evt, event, i);
                }
              "
              type="checkbox"
            />
            <img width="150" v-if="ent.cover" :src="ent.cover.url" />
            <p>Title: {{ ent.title }}</p>
          </td>
          <td>
            <div
              class="jury_listing"
              style="font-size: 12px"
              v-for="j in groupedEvaluations[ent.id]"
              :key="j.id"
            >
              {{ j.jury.email }}
              <i
                @click="deleteEvaluation(j.id)"
                style="font-size: 8px; cursor: pointer"
                class="ml12 trash icon"
              ></i>
            </div>
            <select
              class="mt12"
              v-if="event.jury && event.jury.length > 0"
              v-model="ent.jury_member"
              @change="assignJury($event, ent)"
            >
              <option value="" selected disabled>Select Jury</option>
              <option v-for="j in event.jury" :value="j.id" :key="j.id">
                {{ j.name }}
              </option>
            </select>
          </td>
          <td>
            <p v-for="(v, k) in ent.metadata" :key="v">{{ k }}: {{ v }}</p>
          </td>
          <td>
            <button @click="deleteEntry(i)" class="red">
              <i class="trash icon"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
.mailPopup {
  position: absolute;
  left: 10vw;
  top: 10vh;
  width: 80vw;
  min-height: 70vh;
  background: #fff;
  z-index: 1;
  display: none;
}

.mailPopup.active {
  display: flex;
  flex-direction: column;
}
.email-content.content {
  border: 1px solid #cacacf;
}
.email-content.content:focus {
  border: 1px solid #bababf;
}
</style>

<script>
import moment from "moment";
import qs from "qs";
import universities from "../universities";
import pagination from "../components/pagination";
import mailPopup from "../components/mail-popup";
import pageHeader from "../components/page-header";
export default {
  name: "registration",
  components: { pageHeader, mailPopup, pagination },
  mixins: [universities],
  props: {
    _id: { default: "" },
    hideBackButton: { default: false },
  },
  data() {
    return {
      total: null,
      registrationPages: 10,
      flagged: [],
      archived: [],
      event: [],
      entries: [],
      sortBy: "",
      state: "registrations",
      counts: [],
      users: [],
      registrations: [],
      juryEvaluations: [],
      dbUnis: [],
      regCounts: {},
      events: [],
      activeEventId: "",
    };
  },
  watch: {
    _id: function () {
      let params = this.$route.params["id"];
      if (this._id) {
        params = this._id;
      }
      this.getEventData(params);
    },
    state: function (st) {
      let params = this.$route.params["id"];
      if (this._id) {
        params = this._id;
      }

      if (st == "flagged") {
        this.$req
          .post(
            "getSubmissions",
            {
              eventId: params,
              page: 1,
              limit: -1,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.registrations = this.flagged;
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
        return;
      }

      if (st == "archived") {
        this.$req
          .post(
            "getSubmissions",
            {
              eventId: params,
              page: 1,
              limit: -1,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.registrations = this.archived;
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
        return;
      }

      if (st != "registrations") {
        this.$req
          .post(
            "getSubmissions",
            {
              eventId: params,
              zone: st,
              page: 1,
              limit: -1,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
      } else {
        this.$req
          .post(
            "getSubmissions",
            {
              eventId: params,
              page: 1,
              limit: 500,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
      }
    },
  },
  async mounted() {
    let params = this.$route.params["id"];
    if (this._id) {
      params = this._id;
    }
    this.getEventData(params);
    await this.$req
      .get("universities?_limit=-1")
      .then((r) => {
        this.dbUnis = r.data;
      })
      .catch((e) => console.log(e));

    let today = moment()._d;
    let filter = qs.stringify({
      _where: {
        Start_lte: today,
        End_gte: today,
        hide: false,
      },
      _limit: -1,
    });
    await this.$req
      .get(`events?${filter}`)
      .then((r) => {
        this.events = r.data;
      })
      .catch((e) => console.log(e));
  },
  computed: {
    groupedEvaluations: function () {
      let obj = {};
      this.juryEvaluations.map((j) => {
        obj[j.event_submission.id] = obj[j.event_submission.id] || [];
        obj[j.event_submission.id].push(j);
      });
      return obj;
    },
    registrationTabs: function () {
      return [
        {
          label: `Registrations (${this.regCounts["Total"]})`,
          count: this.regCounts["Total"],
          value: "registrations",
        },
        {
          label: `Flagged (${this.flagged.length})`,
          value: "flagged",
          count: this.flagged.length,
        },
        {
          label: `Archived (${this.archived.length})`,
          value: "archived",
          count: this.archived.length,
        },
        {
          label: `Submissions (${this.regCounts["Submissions"]})`,
          value: "submissions",
          count: this.regCounts["Submissions"],
        },
        {
          label: `Students (${this.regCounts["Student"]})`,
          value: "student",
          count: this.regCounts["Student"],
        },
        {
          label: `Professionals (${this.regCounts["Professional"]})`,
          value: "professional",
          count: this.regCounts["Professional"],
        },
        {
          label: `Faculty (${this.regCounts["Faculty"]})`,
          value: "faculty",
          count: this.regCounts["Faculty"],
        },
        {
          label: `North (${this.regCounts["North"]})`,
          value: "north",
          count: this.regCounts["North"],
        },
        {
          label: `South (${this.regCounts["South"]})`,
          value: "south",
          count: this.regCounts["South"],
        },
        {
          label: `East (${this.regCounts["East"]})`,
          value: "east",
          count: this.regCounts["East"],
        },
        {
          label: `West (${this.regCounts["West"]})`,
          value: "west",
          count: this.regCounts["West"],
        },
      ].filter((t) => t["count"] > 0);
    },
    emails: function () {
      let emails = [];
      let regs = [...this.registrations, ...this.flagged];
      regs.map((r) => {
        emails = [...emails, ...(r.users || [])];
      });
      return emails;
    },
    filteredRegistrations: function () {
      let universities = this.archUniversities;
      let regions = ["north", "south", "east", "west"];
      let registrations = {};
      for (let reg of regions) {
        let regionUnis = universities
          .filter((u) => {
            return u.Region.toLowerCase() == reg.toLowerCase();
          })
          .map((u) => u.College);

        let dbUnis = this.dbUnis
          .filter((u) => {
            return u.zone.toLowerCase() == reg.toLowerCase();
          })
          .map((u) => u.name + ", " + u.city);

        regionUnis = [...regionUnis, ...dbUnis];

        registrations[reg] = this.registrations.filter((r) => {
          if (r.users) {
            return (
              r.users
                .map((u) => u.institute)
                .filter((inst) => {
                  return regionUnis.includes(inst); // .filter(ru => this.$bus.similarity(ru, inst) > 0.9).length > 0
                }).length > 0
            );
          }
        });
      }

      registrations["registrations"] = this.registrations;
      registrations["submissions"] = this.registrations.filter((reg) => {
        return reg.files.length > 0;
      });
      // if (['north', 'south', 'east', 'west'].includes(this.state)) {
      //   universities = universities.filter(u => {
      //     return u.Region == ['north', 'south', 'east', 'west'].indexOf(this.state) + 1
      //   })
      // }
      // if (this.state !== 'registrations') {
      //   return this.registrations.filter(r => {
      //     if (r.users) {
      //       return r.users.map(u => u.institute).filter(inst => universities.map(u => u.College).includes(inst)).length > 0
      //     }
      //   })
      // }
      return registrations;
    },
  },
  methods: {
    changeState: function (state) {
      this.state = state;
    },
    handleDuplicates: function (regs) {
      let duplicates = regs.filter((d, i) => {
        let ix = regs.findIndex(
          (reg) => reg.users?.[0]?.email == d.users?.[0]?.email
        );
        return ix != i;
      });
      duplicates.map((d) => {
        this.$req
          .delete(`event-submissions/${d.id}`, this.$bus.headers)
          .then(() => {
            this.regCounts[this.state] -= 1;
          })
          .catch((e) => console.log(e));
      });
    },
    filterDuplicates: function (regs) {
      let nonDuplicates = regs.filter((d, i) => {
        let ix = regs.findIndex(
          (reg) => reg.users?.[0]?.email == d.users?.[0]?.email
        );
        return ix == i;
      });
      this.flagged = nonDuplicates.filter((d) => {
        let institutes = d.users.map((u) => {
          if (u.institute || u.type == "Professional") {
            return true;
          } else {
            return false;
          }
        });
        let names = d.users.map((u) => {
          if (u.name && u.name != " ") {
            return true;
          } else {
            return false;
          }
        });
        return institutes.includes(false) || names.includes(false);
      });
      this.archived = nonDuplicates.filter((d) => d.archived);

      return nonDuplicates
        .filter((d) => {
          let institutes = d.users.map((u) => {
            if (u.institute || u.type == "Professional") {
              return true;
            } else {
              return false;
            }
          });
          let names = d.users.map((u) => {
            if (u.name && u.name != " ") {
              return true;
            } else {
              return false;
            }
          });
          return (
            !institutes.includes(false) && !names.includes(false) && !d.archived
          );
        })
        .sort(
          (d1, d2) =>
            moment(d2.created_at).unix() - moment(d1.created_at).unix()
        );
    },
    getDataForPage: async function (page) {
      let params = this.$route.params["id"];
      if (this._id) {
        params = this._id;
      }
      if (this.state != "registrations") {
        await this.$req
          .post(
            "getSubmissions",
            {
              limit: 500,
              eventId: params,
              zone: this.state,
              page: page + 1,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
      } else {
        await this.$req
          .post(
            "getSubmissions",
            {
              limit: 500,
              eventId: params,
              page: page + 1,
            },
            this.$bus.headers
          )
          .then((r) => {
            this.registrations = this.filterDuplicates(r.data);
            this.handleDuplicates(r.data);
          })
          .catch((e) => console.log(e));
      }
    },
    getEventData: async function (params) {
      // Getting counts
      if (params) {
        const counts = await this.$req
          .post(
            `getRegistrationCounts`,
            {
              eventId: params,
            },
            this.$bus.headers
          )
          .catch((e) => console.log(e));
        if (counts) {
          this.regCounts = counts.data;
        }

        const subs = await this.$req
          .post(
            "getSubmissions",
            {
              eventId: params,
              page: 1,
              limit: -1,
            },
            this.$bus.headers
          )
          .catch((e) => console.log(e));
        if (subs) {
          this.registrations = this.filterDuplicates(subs.data);
          this.handleDuplicates(subs.data);
        }
      }

      // this.$req.get(`event-submissions?event.id=${params}&_limit=-1&_start=0`, {
      //   headers: {
      //     Authorization: `Bearer ${this.$bus.token}`
      //   }
      // }).then((r) => {
      //   this.registrations = r.data.sort((reg1, reg2) => moment(reg2.created_at).unix() - moment(reg1.created_at).unix())
      // })
      const event = await this.$req.get(`events/${params}`, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`,
        },
      });
      if (event) {
        this.event = event.data;
      }
      const juryEvaluations = await this.$req
        .get(`jury-evaluations?event.id=${params}&_limit=-1`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        // .then(r => {
        //   this.juryEvaluations = r.data;
        // })
        .catch((e) => console.log(e));
      if (juryEvaluations) {
        this.juryEvaluations = juryEvaluations.data;
      }
    },
    deleteEvaluation: function (id) {
      this.$req
        .delete(`jury-evaluations/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .then(() => {
          let ix = this.juryEvaluations.findIndex((j) => j.id == id);
          this.juryEvaluations.splice(ix, 1);
        })
        .catch((e) => console.log(e));
    },
    showMailPopup: function () {
      let mailPopup = this.$refs.mailPopup;
      mailPopup.active = !mailPopup.active;
    },
    showJuryMailPopup: function () {
      let mailPopup = this.$refs.juryMailPopup;
      mailPopup.active = !mailPopup.active;
    },
    displayEntry: function (evt, event, ix) {
      const display = evt.target.checked;
      let conf = confirm(
        "Are you sure you would like to display this submission"
      );
      if (conf) {
        this.$req
          .put(
            `event-submissions/${this.registrations[ix].id}`,
            {
              display,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$bus.token}`,
              },
            }
          )
          .then(() => {
            this.registrations[ix]["display"] = display;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    assignJury: function (event, ent) {
      let val = event.target.value;
      this.$req
        .post(
          `jury-evaluations`,
          {
            event: this.event.id,
            event_submission: ent.id,
            jury: val,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.juryEvaluations.push(r.data);
        })
        .catch((e) => console.log(e));
    },
    deleteEntry(ix) {
      let conf = confirm(
        "Are you sure you would like to delete this registration"
      );
      if (conf) {
        this.$req
          .put(
            `event-submissions/${this.registrations[ix].id}`,
            {
              archived: true,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$bus.token}`,
              },
            }
          )
          .then(() => {
            this.registrations.splice(ix, 1);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    exportCsv: function () {
      let fields = [
        "name",
        "email",
        "contact",
        "type",
        "institute",
        "yearOfGraduation",
      ];
      let json = this.registrations.map((r) => {
        let obj = {};
        obj["id"] = r.id;
        obj["registration date"] = r.created_at;
        for (let [i, u] of r.users.entries()) {
          for (let f of fields) {
            obj[f + ":" + i] = '"' + u[f] + '"';
            // console.log(i)
            // obj[f] = u[f]
          }
        }
        obj["submission links"] = r.files.map((f) => f.url).join(" \t ");
        return obj;
      });
      // json2excel({
      //   json,
      //   name: 'user-info-data',
      //   formateDate: 'yyyy/mm/dd'
      // });
      this.downloadCsv(json);
      return json;
    },
    fnExcelReport: function () {
      var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
      var j = 0;
      let tab = document.getElementById("regTable"); // id of table
      let txtArea1 = document.getElementById("txtArea1"); // id of table

      for (j = 0; j < tab.rows.length; j++) {
        tab_text = tab_text + tab.rows[j].innerHTML + "</tr>";
        //tab_text=tab_text+"</tr>";
      }

      tab_text = tab_text + "</table>";
      tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ""); //remove if u want links in your table
      tab_text = tab_text.replace(/<img[^>]*>/gi, ""); // remove if u want images in your table
      tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ""); // reomves input params

      var ua = window.navigator.userAgent;
      var msie = ua.indexOf("MSIE ");
      // eslint-disable-next-line no-useless-escape
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        // If Internet Explorer
        txtArea1.document.open("txt/html", "replace");
        txtArea1.document.write(tab_text);
        txtArea1.document.close();
        txtArea1.focus();
        var sa = txtArea1.document.execCommand(
          "SaveAs",
          true,
          "Say Thanks to Sumit.xls"
        );
      } //other browser not tested on IE 11
      else
        sa = window.open(
          "data:application/vnd.ms-excel," + encodeURIComponent(tab_text)
        );
      return sa;
    },
    downloadCsv: function (json) {
      var csv = [];
      var maxlen = Math.max(...json.map((j) => Object.keys(j).length));
      var maxjson = json.filter((j) => Object.keys(j).length == maxlen);
      if (json.length) {
        var keys = Object.keys(maxjson[0]);
        csv.push(keys.join(","));
        json.forEach((item) => {
          let vals = keys.map((key) => item[key] || "");
          csv.push(vals.join(","));
        });
      }

      csv = csv.join("\n");
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = this.event.Title + ".csv";
      hiddenElement.click();
    },
  },
};
</script>
